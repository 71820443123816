/**
 * @file: VehicleInspectionTaskQuotationDetailService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskQuotationDetailListFilter, VehicleInspectionTaskQuotationDetail, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions } from 'model';

export class VehicleInspectionTaskQuotationDetailServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskQuotationDetailListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskQuotationDetail> : VehicleInspectionTaskQuotationDetail[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-details', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskQuotationDetail | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-details/:id', { id })
      .future();
  }
}