/**
 * @file: Acl.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export const AclObjectList = {
  AclFullAccess: 'AclFullAccess',
  AclReadonlyAccess: 'AclReadonlyAccess',
  AdminUserFullAccess: 'AdminUserFullAccess',
  AdminUserReadonlyAccess: 'AdminUserReadonlyAccess',
  AdminUserAclFullAccess: 'AdminUserAclFullAccess',
  AdminUserAclReadonlyAccess: 'AdminUserAclReadonlyAccess',
  AgentFullAccess: 'AgentFullAccess',
  AgentReadonlyAccess: 'AgentReadonlyAccess',
  AreaReadonlyAccess: 'AreaReadonlyAccess',
  AuditLogFullAccess: 'AuditLogFullAccess',
  AuditLogReadonlyAccess: 'AuditLogReadonlyAccess',
  AutoPartNameFullAccess: 'AutoPartNameFullAccess',
  AutoPartNameReadonlyAccess: 'AutoPartNameReadonlyAccess',
  CustomerFullAccess: 'CustomerFullAccess',
  CustomerReadonlyAccess: 'CustomerReadonlyAccess',
  DataExportTaskFullAccess: 'DataExportTaskFullAccess',
  DataExportTaskReadonlyAccess: 'DataExportTaskReadonlyAccess',
  InspectionToolReadonlyAccess: 'InspectionToolReadonlyAccess',
  MaintenanceServiceSubjectFullAccess: 'MaintenanceServiceSubjectFullAccess',
  MaintenanceServiceSubjectReadonlyAccess:
    'MaintenanceServiceSubjectReadonlyAccess',
  OpenApiAppFullAccess: 'OpenApiAppFullAccess',
  OpenApiAppReadonlyAccess: 'OpenApiAppReadonlyAccess',
  OpenApiAppAuthorizedStoreFullAccess: 'OpenApiAppAuthorizedStoreFullAccess',
  OpenApiAppAuthorizedStoreReadonlyAccess:
    'OpenApiAppAuthorizedStoreReadonlyAccess',
  OpenApiUserFullAccess: 'OpenApiUserFullAccess',
  OpenApiUserReadonlyAccess: 'OpenApiUserReadonlyAccess',
  OrgGroupFullAccess: 'OrgGroupFullAccess',
  OrgGroupReadonlyAccess: 'OrgGroupReadonlyAccess',
  OrgMemberFullAccess: 'OrgMemberFullAccess',
  OrgMemberReadonlyAccess: 'OrgMemberReadonlyAccess',
  OrgSubscribedUserFullAccess: 'OrgSubscribedUserFullAccess',
  OrgSubscribedUserReadonlyAccess: 'OrgSubscribedUserReadonlyAccess',
  OrgTeamFullAccess: 'OrgTeamFullAccess',
  OrgTeamReadonlyAccess: 'OrgTeamReadonlyAccess',
  OrgUserFullAccess: 'OrgUserFullAccess',
  OrgUserReadonlyAccess: 'OrgUserReadonlyAccess',
  OrgUserAclFullAccess: 'OrgUserAclFullAccess',
  OrgUserAclReadonlyAccess: 'OrgUserAclReadonlyAccess',
  OrgUserAppleSignInBindingFullAccess: 'OrgUserAppleSignInBindingFullAccess',
  OrgUserAppleSignInBindingReadonlyAccess:
    'OrgUserAppleSignInBindingReadonlyAccess',
  OrgUserVisibleStoreFullAccess: 'OrgUserVisibleStoreFullAccess',
  OrgUserVisibleStoreReadonlyAccess: 'OrgUserVisibleStoreReadonlyAccess',
  OrgUserVisibleStoreAclFullAccess: 'OrgUserVisibleStoreAclFullAccess',
  OrgUserVisibleStoreAclReadonlyAccess: 'OrgUserVisibleStoreAclReadonlyAccess',
  OrgUserWeixinBindingReadonlyAccess: 'OrgUserWeixinBindingReadonlyAccess',
  OrgWeixinOpenAuthorizationEventFullAccess:
    'OrgWeixinOpenAuthorizationEventFullAccess',
  OrgWeixinOpenAuthorizationEventReadonlyAccess:
    'OrgWeixinOpenAuthorizationEventReadonlyAccess',
  OrgWeixinTemplateConfFullAccess: 'OrgWeixinTemplateConfFullAccess',
  OrgWeixinTemplateConfReadonlyAccess: 'OrgWeixinTemplateConfReadonlyAccess',
  OrganizationFullAccess: 'OrganizationFullAccess',
  OrganizationReadonlyAccess: 'OrganizationReadonlyAccess',
  ProductFullAccess: 'ProductFullAccess',
  ProductReadonlyAccess: 'ProductReadonlyAccess',
  ProductAgentFullAccess: 'ProductAgentFullAccess',
  ProductAgentReadonlyAccess: 'ProductAgentReadonlyAccess',
  ProductAuthorizationContractFullAccess:
    'ProductAuthorizationContractFullAccess',
  ProductAuthorizationContractReadonlyAccess:
    'ProductAuthorizationContractReadonlyAccess',
  ProductAuthorizationRequestFullAccess:
    'ProductAuthorizationRequestFullAccess',
  ProductAuthorizationRequestReadonlyAccess:
    'ProductAuthorizationRequestReadonlyAccess',
  ProductAuthorizationRequestEventFullAccess:
    'ProductAuthorizationRequestEventFullAccess',
  ProductAuthorizationRequestEventReadonlyAccess:
    'ProductAuthorizationRequestEventReadonlyAccess',
  ProductBrandFullAccess: 'ProductBrandFullAccess',
  ProductBrandReadonlyAccess: 'ProductBrandReadonlyAccess',
  ProductDetailFullAccess: 'ProductDetailFullAccess',
  ProductDetailReadonlyAccess: 'ProductDetailReadonlyAccess',
  QuotationPartCatalogFullAccess: 'QuotationPartCatalogFullAccess',
  QuotationPartCatalogReadonlyAccess: 'QuotationPartCatalogReadonlyAccess',
  ReportFullAccess: 'ReportFullAccess',
  ReportReadonlyAccess: 'ReportReadonlyAccess',
  ServiceEditionFullAccess: 'ServiceEditionFullAccess',
  ServiceEditionReadonlyAccess: 'ServiceEditionReadonlyAccess',
  StatsFullAccess: 'StatsFullAccess',
  StatsReadonlyAccess: 'StatsReadonlyAccess',
  StoreFullAccess: 'StoreFullAccess',
  StoreReadonlyAccess: 'StoreReadonlyAccess',
  StoreConfigurationFullAccess: 'StoreConfigurationFullAccess',
  StoreConfigurationReadonlyAccess: 'StoreConfigurationReadonlyAccess',
  StoreCustomerFullAccess: 'StoreCustomerFullAccess',
  StoreCustomerReadonlyAccess: 'StoreCustomerReadonlyAccess',
  StoreGatewayDeviceFullAccess: 'StoreGatewayDeviceFullAccess',
  StoreGatewayDeviceReadonlyAccess: 'StoreGatewayDeviceReadonlyAccess',
  StoreMediaFullAccess: 'StoreMediaFullAccess',
  StoreMediaReadonlyAccess: 'StoreMediaReadonlyAccess',
  StoreObdDeviceFullAccess: 'StoreObdDeviceFullAccess',
  StoreObdDeviceReadonlyAccess: 'StoreObdDeviceReadonlyAccess',
  StoreServiceEnrollmentFullAccess: 'StoreServiceEnrollmentFullAccess',
  StoreServiceEnrollmentReadonlyAccess: 'StoreServiceEnrollmentReadonlyAccess',
  StoreServiceRemindRecordFullAccess: 'StoreServiceRemindRecordFullAccess',
  StoreServiceRemindRecordReadonlyAccess:
    'StoreServiceRemindRecordReadonlyAccess',
  SysBatchJobFullAccess: 'SysBatchJobFullAccess',
  SysBatchJobReadonlyAccess: 'SysBatchJobReadonlyAccess',
  SysErrorLogFullAccess: 'SysErrorLogFullAccess',
  SysErrorLogReadonlyAccess: 'SysErrorLogReadonlyAccess',
  SysTaskSchedulerExecLogFullAccess: 'SysTaskSchedulerExecLogFullAccess',
  SysTaskSchedulerExecLogReadonlyAccess:
    'SysTaskSchedulerExecLogReadonlyAccess',
  SysTaskSchedulerInfoFullAccess: 'SysTaskSchedulerInfoFullAccess',
  SysTaskSchedulerInfoReadonlyAccess: 'SysTaskSchedulerInfoReadonlyAccess',
  UserFullAccess: 'UserFullAccess',
  UserReadonlyAccess: 'UserReadonlyAccess',
  UserVehicleBindingFullAccess: 'UserVehicleBindingFullAccess',
  UserVehicleBindingReadonlyAccess: 'UserVehicleBindingReadonlyAccess',
  UserVehicleBindingCertificateFullAccess:
    'UserVehicleBindingCertificateFullAccess',
  UserVehicleBindingCertificateReadonlyAccess:
    'UserVehicleBindingCertificateReadonlyAccess',
  UserWeixinBindingFullAccess: 'UserWeixinBindingFullAccess',
  UserWeixinBindingReadonlyAccess: 'UserWeixinBindingReadonlyAccess',
  VehicleFullAccess: 'VehicleFullAccess',
  VehicleReadonlyAccess: 'VehicleReadonlyAccess',
  VehicleConstructionTemplateFullAccess:
    'VehicleConstructionTemplateFullAccess',
  VehicleConstructionTemplateReadonlyAccess:
    'VehicleConstructionTemplateReadonlyAccess',
  VehicleDeliveryCheckTemplateFullAccess:
    'VehicleDeliveryCheckTemplateFullAccess',
  VehicleDeliveryCheckTemplateReadonlyAccess:
    'VehicleDeliveryCheckTemplateReadonlyAccess',
  VehicleDeliveryCheckTemplateBarrierFullAccess:
    'VehicleDeliveryCheckTemplateBarrierFullAccess',
  VehicleDeliveryCheckTemplateBarrierReadonlyAccess:
    'VehicleDeliveryCheckTemplateBarrierReadonlyAccess',
  VehicleInfoFullAccess: 'VehicleInfoFullAccess',
  VehicleInfoReadonlyAccess: 'VehicleInfoReadonlyAccess',
  VehicleInspectionCommentFullAccess: 'VehicleInspectionCommentFullAccess',
  VehicleInspectionCommentReadonlyAccess:
    'VehicleInspectionCommentReadonlyAccess',
  VehicleInspectionSiteFullAccess: 'VehicleInspectionSiteFullAccess',
  VehicleInspectionSiteReadonlyAccess: 'VehicleInspectionSiteReadonlyAccess',
  VehicleInspectionSiteCategoryFullAccess:
    'VehicleInspectionSiteCategoryFullAccess',
  VehicleInspectionSiteCategoryReadonlyAccess:
    'VehicleInspectionSiteCategoryReadonlyAccess',
  VehicleInspectionSiteCheckItemFullAccess:
    'VehicleInspectionSiteCheckItemFullAccess',
  VehicleInspectionSiteCheckItemReadonlyAccess:
    'VehicleInspectionSiteCheckItemReadonlyAccess',
  VehicleInspectionSiteCheckItemMediaFullAccess:
    'VehicleInspectionSiteCheckItemMediaFullAccess',
  VehicleInspectionSiteCheckItemMediaReadonlyAccess:
    'VehicleInspectionSiteCheckItemMediaReadonlyAccess',
  VehicleInspectionSiteCheckItemOptionFullAccess:
    'VehicleInspectionSiteCheckItemOptionFullAccess',
  VehicleInspectionSiteCheckItemOptionReadonlyAccess:
    'VehicleInspectionSiteCheckItemOptionReadonlyAccess',
  VehicleInspectionSiteVersionFullAccess:
    'VehicleInspectionSiteVersionFullAccess',
  VehicleInspectionSiteVersionReadonlyAccess:
    'VehicleInspectionSiteVersionReadonlyAccess',
  VehicleInspectionSubjectFullAccess: 'VehicleInspectionSubjectFullAccess',
  VehicleInspectionSubjectReadonlyAccess:
    'VehicleInspectionSubjectReadonlyAccess',
  VehicleInspectionTaskReadonlyAccess: 'VehicleInspectionTaskReadonlyAccess',
  VehicleInspectionTaskAssignmentReadonlyAccess:
    'VehicleInspectionTaskAssignmentReadonlyAccess',
  VehicleInspectionTaskCheckSiteReadonlyAccess:
    'VehicleInspectionTaskCheckSiteReadonlyAccess',
  VehicleInspectionTaskCheckSiteItemReadonlyAccess:
    'VehicleInspectionTaskCheckSiteItemReadonlyAccess',
  VehicleInspectionTaskCheckSiteItemDataReadonlyAccess:
    'VehicleInspectionTaskCheckSiteItemDataReadonlyAccess',
  VehicleInspectionTaskCheckSiteItemMediaReadonlyAccess:
    'VehicleInspectionTaskCheckSiteItemMediaReadonlyAccess',
  VehicleInspectionTaskCustomIssueReadonlyAccess:
    'VehicleInspectionTaskCustomIssueReadonlyAccess',
  VehicleInspectionTaskCustomIssueMediaReadonlyAccess:
    'VehicleInspectionTaskCustomIssueMediaReadonlyAccess',
  VehicleInspectionTaskDeliveryCheckFullAccess:
    'VehicleInspectionTaskDeliveryCheckFullAccess',
  VehicleInspectionTaskDeliveryCheckReadonlyAccess:
    'VehicleInspectionTaskDeliveryCheckReadonlyAccess',
  VehicleInspectionTaskDeliveryCheckMediaFullAccess:
    'VehicleInspectionTaskDeliveryCheckMediaFullAccess',
  VehicleInspectionTaskDeliveryCheckMediaReadonlyAccess:
    'VehicleInspectionTaskDeliveryCheckMediaReadonlyAccess',
  VehicleInspectionTaskEventReadonlyAccess:
    'VehicleInspectionTaskEventReadonlyAccess',
  VehicleInspectionTaskFlowFullAccess: 'VehicleInspectionTaskFlowFullAccess',
  VehicleInspectionTaskFlowReadonlyAccess:
    'VehicleInspectionTaskFlowReadonlyAccess',
  VehicleInspectionTaskJobReadonlyAccess:
    'VehicleInspectionTaskJobReadonlyAccess',
  VehicleInspectionTaskJobDetailReadonlyAccess:
    'VehicleInspectionTaskJobDetailReadonlyAccess',
  VehicleInspectionTaskJobMediaReadonlyAccess:
    'VehicleInspectionTaskJobMediaReadonlyAccess',
  VehicleInspectionTaskQuotationFullAccess:
    'VehicleInspectionTaskQuotationFullAccess',
  VehicleInspectionTaskQuotationReadonlyAccess:
    'VehicleInspectionTaskQuotationReadonlyAccess',
  VehicleInspectionTaskQuotationDetailFullAccess:
    'VehicleInspectionTaskQuotationDetailFullAccess',
  VehicleInspectionTaskQuotationDetailReadonlyAccess:
    'VehicleInspectionTaskQuotationDetailReadonlyAccess',
  VehicleInspectionTaskQuotationFormFullAccess:
    'VehicleInspectionTaskQuotationFormFullAccess',
  VehicleInspectionTaskQuotationFormReadonlyAccess:
    'VehicleInspectionTaskQuotationFormReadonlyAccess',
  VehicleInspectionTaskQuotationIssueReadonlyAccess:
    'VehicleInspectionTaskQuotationIssueReadonlyAccess',
  VehicleInspectionTaskSubjectReadonlyAccess:
    'VehicleInspectionTaskSubjectReadonlyAccess',
  VehicleInspectionTaskTemplateSnapshotFullAccess:
    'VehicleInspectionTaskTemplateSnapshotFullAccess',
  VehicleInspectionTaskTemplateSnapshotReadonlyAccess:
    'VehicleInspectionTaskTemplateSnapshotReadonlyAccess',
  VehicleInspectionTaskTroubleCodeReadonlyAccess:
    'VehicleInspectionTaskTroubleCodeReadonlyAccess',
  VehicleInspectionTemplateFullAccess: 'VehicleInspectionTemplateFullAccess',
  VehicleInspectionTemplateReadonlyAccess:
    'VehicleInspectionTemplateReadonlyAccess',
  VehicleInspectionTemplateBarrierFullAccess:
    'VehicleInspectionTemplateBarrierFullAccess',
  VehicleInspectionTemplateBarrierReadonlyAccess:
    'VehicleInspectionTemplateBarrierReadonlyAccess',
  VehicleMaintenanceServicePlanFullAccess:
    'VehicleMaintenanceServicePlanFullAccess',
  VehicleMaintenanceServicePlanReadonlyAccess:
    'VehicleMaintenanceServicePlanReadonlyAccess',
  VehicleMaintenanceServicePlanDetailFullAccess:
    'VehicleMaintenanceServicePlanDetailFullAccess',
  VehicleMaintenanceServicePlanDetailReadonlyAccess:
    'VehicleMaintenanceServicePlanDetailReadonlyAccess',
  VehicleModelFullAccess: 'VehicleModelFullAccess',
  VehicleModelReadonlyAccess: 'VehicleModelReadonlyAccess',
  VehiclePendingIssueFullAccess: 'VehiclePendingIssueFullAccess',
  VehiclePendingIssueReadonlyAccess: 'VehiclePendingIssueReadonlyAccess',
  VehiclePendingIssueDetailFullAccess: 'VehiclePendingIssueDetailFullAccess',
  VehiclePendingIssueDetailReadonlyAccess:
    'VehiclePendingIssueDetailReadonlyAccess',
  VehiclePendingIssueMediaFullAccess: 'VehiclePendingIssueMediaFullAccess',
  VehiclePendingIssueMediaReadonlyAccess:
    'VehiclePendingIssueMediaReadonlyAccess',
  VehicleReceptionOrderFullAccess: 'VehicleReceptionOrderFullAccess',
  VehicleReceptionOrderReadonlyAccess: 'VehicleReceptionOrderReadonlyAccess',
  VehicleReportOpenLogFullAccess: 'VehicleReportOpenLogFullAccess',
  VehicleReportOpenLogReadonlyAccess: 'VehicleReportOpenLogReadonlyAccess',
  VehicleServiceSubjectFullAccess: 'VehicleServiceSubjectFullAccess',
  VehicleServiceSubjectReadonlyAccess: 'VehicleServiceSubjectReadonlyAccess',
  VehicleSubscriptionFullAccess: 'VehicleSubscriptionFullAccess',
  VehicleSubscriptionReadonlyAccess: 'VehicleSubscriptionReadonlyAccess',
  VehicleSubscriptionDetailFullAccess: 'VehicleSubscriptionDetailFullAccess',
  VehicleSubscriptionDetailReadonlyAccess:
    'VehicleSubscriptionDetailReadonlyAccess',
  VehicleSystemModuleFullAccess: 'VehicleSystemModuleFullAccess',
  VehicleSystemModuleReadonlyAccess: 'VehicleSystemModuleReadonlyAccess',
  WeixinOpenIntegrationFullAccess: 'WeixinOpenIntegrationFullAccess',
  WeixinOpenIntegrationReadonlyAccess: 'WeixinOpenIntegrationReadonlyAccess',
};
