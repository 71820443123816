import { TransFunction } from 'app';
import { AppContext } from 'app/AppContext';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { withAcl } from 'lib/decorators/acl';
import { getItemSource } from 'lib/helpers';
import {
  BreadcrumbItem,
  Button,
  Column,
  DataTable,
  Page,
  Portlet,
} from 'lib/metronic/components';
import {
  AclObjectList,
  ConstructionTemplateConfigInfo,
  Identity,
  Store,
} from 'model';
import qs from 'qs';
import { Component, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { constructionTemplateConfigService } from 'services';
import {
  authenticate,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  ItemSourceLabel,
  ListToolbar,
  ToolbarItemsBuilder,
} from 'shared/components';
import { loadAsyncList } from 'utils';
import { constructionTemplateConfigListActions } from '../duck/actions';
import { ConstructionTemplateConfigs } from '../duck/states';
import { SelectStoreModal } from './SelectStoreModal';

import './index.scss';

const pageIcon =
  require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg').default;

type ColumnType = Column<ConstructionTemplateConfigInfo>;

interface Props extends CommonEntityListProps, LocalizeContextProps {
  configs: ConstructionTemplateConfigs;
  history: History;
  identity: Identity;
  activeStorId?: number | null;
}

export type DeliveryCheckTemplateManagerProps = Props;

function mapStateToProps(
  state: AppState,
  ownProps: RouteViewProps,
): Partial<Props> {
  return {
    history: ownProps.history,
    configs: state.inspection.constructionTemplateConfigs,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    activeStorId: state.activeStoreId,
    identity: state.identity!,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

type StoreSelectionInfo = { errorMsg?: string; isValid: boolean } & (
  | {
      purpose: 'add_config';
    }
  | {
      purpose: 'duplicate_config';
      fromConfig: ConstructionTemplateConfigInfo;
    }
);

type State = {
  storeSelection?: StoreSelectionInfo;
};

@withAcl()
class ConstructionTemplateConfigListImpl extends Component<Props, State> {
  state: State = {};

  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    {
      text: (
        <Translate id="inspection.breadcrumb.construction_template_config" />
      ),
    },
  ];

  private columns: ColumnType[] | undefined;

  componentDidMount() {
    this.loadConfigs();
  }

  render() {
    const { trans, configs } = this.props;
    return (
      <Page
        title={trans('construction_tpl.list.title')}
        fullAccessRight={AclObjectList.VehicleConstructionTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleConstructionTemplateReadonlyAccess
        }
        error={configs.error}
        className="construction-tpl-config-list"
      >
        <Page.Header>
          <Page.Header.Main>
            <Page.Breadcrumb items={this.breadcrumbs} />
          </Page.Header.Main>
          <Page.Header.Toolbar>{this.renderToolbar()}</Page.Header.Toolbar>
        </Page.Header>
        <Page.Content>
          <Portlet mobile>
            <Portlet.Header
              size="large"
              title={trans('construction_tpl.list.title')}
              icon={pageIcon}
              iconColor="brand"
              onRefresh={this.onRefresh}
            />
            <Portlet.Body>
              {this.renderDataList()}
              {this.renderConfirmDeleteModal()}
              {this.renderSelectStoreModal()}
            </Portlet.Body>
          </Portlet>
        </Page.Content>
      </Page>
    );
  }

  renderToolbar() {
    const { configs } = this.props;
    const builder = new ToolbarItemsBuilder<any>();
    builder
      .button({
        placement: 'right',
        buttonType: 'refresh',
        onClick: this.onRefresh,
      })
      .button({
        placement: 'right',
        buttonType: 'add',
        onClick: () => {
          this.onAdd();
        },
      });
    return (
      <ListToolbar<any> filter={configs.filter || {}} items={builder.build()} />
    );
  }

  renderConfirmDeleteModal() {
    const { configs } = this.props;
    return (
      <ConfirmDeleteModal
        localeSegment={'construction_tpl'}
        isOpen={Boolean(configs.itemsBeingDeleted?.[0])}
        isDeleting={configs.isDeleting}
        error={configs.lastDeleteError}
        onConfirm={this.onConfirmDelete}
        onCancel={this.onCancelDelete}
      />
    );
  }

  onCancelStoreSelectionModal = () => {
    this.setState({ storeSelection: undefined });
  };

  onConfirmStoreSelectionModal = ({ storeId }: { storeId: number }) => {
    const { storeSelection } = this.state;
    if (!storeSelection) return;
    if (storeSelection.purpose === 'add_config') {
      this.navigateToConfigDetail(this.props.identity.orgId, storeId);
    } else {
      constructionTemplateConfigService
        .duplicateConstructionTemplateConfig(storeId, {
          orgId: storeSelection.fromConfig.orgId,
          storeId: storeSelection.fromConfig.storeId,
        })
        .then(() => {
          this.setState({ storeSelection: undefined });
          this.props.dispatch(
            constructionTemplateConfigListActions.invalidate(),
          );
        })
        .catch(err => {
          console.error(err);
          this.setState({
            storeSelection: {
              ...storeSelection,
              errorMsg: err.message,
            },
          });
        });
    }
  };

  onStoreSelectionChange = (store: Store | null) => {
    const { storeSelection } = this.state;
    if (!storeSelection) return;
    const existingConfig = this.props.configs.result?.find(
      x => x.orgId > 0 && x.storeId === (store?.id ?? 0),
    );
    if (existingConfig) {
      this.setState({
        storeSelection: {
          ...storeSelection,
          errorMsg: getString('construction_tpl.error.config_already_exists'),
          isValid: false,
        },
      });
    } else {
      this.setState({
        storeSelection: {
          ...storeSelection,
          errorMsg: undefined,
          isValid: true,
        },
      });
    }
  };

  renderSelectStoreModal() {
    const { storeSelection } = this.state;
    return (
      <SelectStoreModal
        isOpen={storeSelection != null}
        isValid={storeSelection?.isValid === true}
        label={
          storeSelection != null ? (
            <Translate
              id={`construction_tpl.modal.${storeSelection.purpose}.label.store`}
            />
          ) : (
            ''
          )
        }
        title={
          storeSelection != null ? (
            <Translate
              id={`construction_tpl.modal.${storeSelection.purpose}.title`}
            />
          ) : (
            ''
          )
        }
        errorMsg={storeSelection?.errorMsg}
        onCancel={this.onCancelStoreSelectionModal}
        onConfirm={this.onConfirmStoreSelectionModal}
        onChange={this.onStoreSelectionChange}
      />
    );
  }

  renderDataList() {
    return (
      <AppContext.Consumer>
        {({ identity }) => {
          const { configs: templates, activeStorId } = this.props;
          let data = templates.result;
          if (data && activeStorId) {
            data = data.filter(x => x.storeId === activeStorId);
          }

          const columns = this.buildColumns(identity);
          return (
            <DataTable<ConstructionTemplateConfigInfo, number>
              columns={columns}
              idProp="id"
              selModel="none"
              data={data}
              isLoading={templates.isLoading}
              minHeight={400}
              selection={templates.selection}
            />
          );
        }}
      </AppContext.Consumer>
    );
  }

  onRefresh = () => {
    this.loadConfigs(true);
  };

  onAdd = () => {
    const { dispatch } = this.props;
    dispatch(
      constructionTemplateConfigListActions.itemBeingCreated({
        orgId: this.props.identity.orgId,
        storeId: this.props.identity.storeId ?? undefined,
      }),
    );
    this.setState({
      storeSelection: {
        purpose: 'add_config',
        isValid: true,
      },
    });
  };

  onConfirmDelete = () => {
    const { dispatch } = this.props;
    dispatch(constructionTemplateConfigListActions.commitItemsBeingDeleted());
  };

  onCancelDelete = () => {
    const { dispatch } = this.props;
    dispatch(constructionTemplateConfigListActions.cancelItemsBeingDeleted());
  };

  loadConfigs(force?: boolean) {
    const { dispatch, configs } = this.props;
    loadAsyncList(
      configs,
      () => dispatch(constructionTemplateConfigListActions.fetch()),
      force,
    );
  }

  buildColumns(identity: Identity): ColumnType[] {
    if (this.columns) return this.columns;
    const { trans } = this.props;
    const columns: ColumnType[] = [
      {
        prop: 'source',
        text: trans('construction_tpl.col.source'),
        width: 50,
        align: 'center',
        render: configInfo => {
          return <ItemSourceLabel value={getItemSource(configInfo)} />;
        },
      },
      {
        prop: 'storeName',
        text: trans('construction_tpl.col.store_name'),
        width: 300,
        render: ({ storeName }) => storeName || '-',
      },
      {
        prop: 'conf',
        text: trans('construction_tpl.col.conf'),
        width: 300,
        render: configInfo => (
          <Translate
            id="construction_tpl.config_info.stats"
            data={configInfo as any}
          />
        ),
      },
      {
        prop: 'enabled',
        text: trans('construction_tpl.col.enabled'),
        width: 80,
        align: 'center',
        render: ({ enabled }) => {
          return (
            <i
              className={classNames('la la-check-circle', {
                'm--font-success': enabled,
                'm--font-metal': !enabled,
              })}
            />
          );
        },
      },
      {
        prop: 'createdAt',
        width: 150,
        align: 'center',
        text: trans('col.created_at'),
      },
    ];
    this.addActionButtons(identity, columns);
    this.columns = columns;
    return columns;
  }

  navigateToConfigDetail(orgId: number, storeId: number) {
    const { history } = this.props;
    const query = qs.stringify({
      target: storeId ? 'store' : orgId ? 'org' : 'system',
      storeId: storeId || undefined,
    });
    setTimeout(() => {
      history.push('/inspection/construction-template-config/detail?' + query);
    }, 0);
  }

  addActionButtons(identity: Identity, columns: ColumnType[]) {
    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 215,
      render: configInfo => {
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(
            constructionTemplateConfigListActions.itemsBeingDeleted([
              configInfo,
            ]),
          );
        };
        const onConfig = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.navigateToConfigDetail(configInfo.orgId, configInfo.storeId);
        };
        const onDuplicate = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.setState({
            storeSelection: {
              purpose: 'duplicate_config',
              fromConfig: configInfo,
              isValid: true,
            },
          });
        };

        const canView = identity.hasAccessRights(
          AclObjectList.VehicleConstructionTemplateFullAccess,
          AclObjectList.VehicleConstructionTemplateReadonlyAccess,
        );

        const canEdit = identity.hasAccessRights(
          AclObjectList.VehicleConstructionTemplateFullAccess,
        );

        const hasPermission = Boolean(
          configInfo.orgId &&
            ((configInfo.storeId &&
              identity.visibleStoreSet.has(configInfo.storeId)) ||
              (!configInfo.storeId && identity.userInfo.isOrgRootUser)),
        );

        return (
          <>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".construction-tpl-config-list"
              title={getString('construction_tpl.tooltip.config')}
              disabled={!canView}
              onClick={onConfig}
            >
              <i className="la la-cog" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".construction-tpl-config-list"
              title={getString('construction_tpl.tooltip.duplicate')}
              disabled={!canEdit}
              onClick={onDuplicate}
            >
              <i className="la la-copy" />
            </Button>
            <Button
              size="small"
              clean
              iconOnly
              data-toggle="tooltip"
              data-container=".construction-tpl-config-list"
              title={getString('construction_tpl.tooltip.delete')}
              onClick={onDelete}
              disabled={!hasPermission}
            >
              <i className="la la-trash" />
            </Button>
          </>
        );
      },
    });
  }
}

export const ConstructionTemplateConfigList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, ConstructionTemplateConfigListImpl>(
    withLocalize<Props>(ConstructionTemplateConfigListImpl) as any,
  ),
);
