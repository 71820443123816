import { constructionTplActions } from 'app/inspection/duck/actions';
import { InlineSvg } from 'lib/components';
import {
  ConstructionTemplateInfo,
  ConstructionTemplateProcedure,
  ConstructionTemplateProcedureType,
} from 'model';
import { nanoid } from 'nanoid/non-secure';
import { ChangeEvent, MouseEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { getString } from 'shared/components';
import { array_move } from 'utils';
import { WithFullAccess, useHasFullAccess } from './WithFullAccess';

export const TemplateDetail = memo(
  ({ template }: { template: ConstructionTemplateInfo }) => {
    const hasFullAccess = useHasFullAccess();
    const dispatch = useDispatch();
    const templateId = template.id;

    const updateTemplate = useCallback(
      (update: (template: ConstructionTemplateInfo) => void) => {
        dispatch(
          constructionTplActions.applyChangesToTemplateConfig(draft => {
            const targetTemplate = draft.templates.find(
              x => x.id === templateId,
            );
            if (targetTemplate) {
              update(targetTemplate);
            }
          }),
        );
      },
      [dispatch, templateId],
    );

    const onNameChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        updateTemplate(x => (x.name = e.target.value));
      },
      [updateTemplate],
    );

    const onDescriptionChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        updateTemplate(x => (x.description = e.target.value));
      },
      [updateTemplate],
    );

    const onAddProcedure = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        updateTemplate(x => {
          x.procedures.push({
            id: nanoid(4),
            name: '',
            type: 'custom',
          });
        });
      },
      [updateTemplate],
    );

    const onProceduresSorted = useCallback(
      (e: { oldIndex: number; newIndex: number }) => {
        const { oldIndex, newIndex } = e;
        if (oldIndex === newIndex) return;
        dispatch(
          constructionTplActions.applyChangesToTemplateConfig(draft => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const template = draft.templates.find(x => x.id === templateId);
            if (!template) return;
            array_move(template.procedures, oldIndex, newIndex);
          }),
        );
      },
      [dispatch, templateId],
    );

    const onProcedureNameChange = useCallback(
      ({ id }: ConstructionTemplateProcedure, value: string) => {
        updateTemplate(x => {
          const procedure = x.procedures.find(p => p.id === id);
          if (!procedure) return;
          procedure.name = value;
        });
      },
      [updateTemplate],
    );

    const onProcedureTypeChange = useCallback(
      (
        { id }: ConstructionTemplateProcedure,
        value: ConstructionTemplateProcedureType,
      ) => {
        updateTemplate(x => {
          const procedure = x.procedures.find(p => p.id === id);
          if (!procedure) return;
          procedure.type = value;
        });
      },
      [updateTemplate],
    );

    const onRemoveProcedure = useCallback(
      ({ id }: ConstructionTemplateProcedure) => {
        updateTemplate(x => {
          const index = x.procedures.findIndex(p => p.id === id);
          if (index >= 0) {
            x.procedures.splice(index, 1);
          }
        });
      },
      [updateTemplate],
    );

    return (
      <div className="construction-tpl-man__detail">
        <dl>
          <dt>
            <Translate id="construction_tpl.template.label.name" />
          </dt>
          <dd>
            <input
              type="text"
              name="name"
              className="form-control"
              value={template.name}
              readOnly={!hasFullAccess}
              onChange={onNameChange}
              placeholder={getString(
                'construction_tpl.template.placeholder.name',
              )}
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <Translate id="construction_tpl.template.label.description" />
          </dt>
          <dd>
            <input
              type="text"
              name="description"
              className="form-control"
              value={template.description ?? ''}
              readOnly={!hasFullAccess}
              onChange={onDescriptionChange}
              placeholder={getString(
                'construction_tpl.template.placeholder.description',
              )}
            />
          </dd>
        </dl>
        <dl>
          <dt>
            <Translate id="construction_tpl.template.label.procedures" />
            <WithFullAccess>
              <a
                href="#"
                onClick={onAddProcedure}
                style={{ marginLeft: '0.5rem' }}
              >
                <i className="la la-plus" />
              </a>
            </WithFullAccess>
          </dt>
          {template.procedures.length === 0 ? (
            <dd>
              <div className="construction-tpl-man__no-procedures">
                <Translate id="construction_tpl.template.empty_text" />
              </div>
            </dd>
          ) : null}
        </dl>
        <SortableProcedureList
          helperClass="construction-tpl-man__procedure--being-dragged"
          lockAxis="y"
          useWindowAsScrollContainer={false}
          distance={3}
          onSortEnd={onProceduresSorted}
          procedures={template.procedures}
          onProcedureNameChange={onProcedureNameChange}
          onProcedureTypeChange={onProcedureTypeChange}
          onRemoveProcedure={onRemoveProcedure}
        />
      </div>
    );
  },
);

const ProcedureList = memo(
  ({
    procedures,
    onProcedureNameChange,
    onProcedureTypeChange,
    onRemoveProcedure,
  }: {
    procedures: ConstructionTemplateProcedure[];
    onProcedureNameChange?: (
      procedure: ConstructionTemplateProcedure,
      name: string,
    ) => void;
    onProcedureTypeChange?: (
      procedure: ConstructionTemplateProcedure,
      type: ConstructionTemplateProcedureType,
    ) => void;
    onRemoveProcedure?: (procedure: ConstructionTemplateProcedure) => void;
  }) => {
    return (
      <WithFullAccess>
        {(_: any, hasRights: boolean) => (
          <div className="construction-tpl-man__procedure-list">
            {procedures.map((procedure, index) => (
              <SortableProcedureListItem
                key={procedure.id}
                index={index}
                procedure={procedure}
                disabled={!hasRights}
                onNameChange={onProcedureNameChange}
                onTypeChange={onProcedureTypeChange}
                onRemove={onRemoveProcedure}
              />
            ))}
          </div>
        )}
      </WithFullAccess>
    );
  },
);

const kProcedureTypes: ConstructionTemplateProcedureType[] = [
  'before',
  'old-prod',
  'new-prod',
  'after',
  'proc',
  'custom',
];

const ProcedureListItem = memo(
  ({
    procedure,
    onNameChange,
    onTypeChange,
    onRemove,
  }: {
    procedure: ConstructionTemplateProcedure;
    onNameChange?: (
      procedure: ConstructionTemplateProcedure,
      name: string,
    ) => void;
    onTypeChange?: (
      procedure: ConstructionTemplateProcedure,
      type: ConstructionTemplateProcedureType,
    ) => void;
    onRemove?: (procedure: ConstructionTemplateProcedure) => void;
  }) => {
    const hasFullAccess = useHasFullAccess();
    const handleNameChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onNameChange?.(procedure, e.target.value);
      },
      [onNameChange, procedure],
    );
    const handleTypeChange = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        onTypeChange?.(procedure, e.target.value as any);
      },
      [onTypeChange, procedure],
    );
    const handleRemove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (
          confirm(
            getString('construction_tpl.template.confirm_remove_procedure'),
          )
        ) {
          onRemove?.(procedure);
        }
      },
      [onRemove, procedure],
    );
    return (
      <div className="construction-tpl-man__procedure">
        <WithFullAccess>
          <DragHandle />
        </WithFullAccess>
        <div className="construction-tpl-man__procedure-info">
          <input
            type="text"
            className="construction-tpl-man__procedure-name"
            value={procedure.name}
            readOnly={!hasFullAccess}
            onChange={handleNameChange}
            placeholder={getString(
              'construction_tpl.template.placeholder.procedure_name',
            )}
          />
          <select
            value={procedure.type}
            onChange={handleTypeChange}
            disabled={!hasFullAccess}
          >
            {kProcedureTypes.map(type => (
              <option key={type} value={type}>
                {getString(`construction_procedure_type.${type}`)}
              </option>
            ))}
          </select>
        </div>
        <WithFullAccess>
          <a
            href="#"
            className="construction-tpl-man__procedure-remove"
            onClick={handleRemove}
          >
            <i className="la la-trash-o" />
          </a>
        </WithFullAccess>
      </div>
    );
  },
);

const SortableProcedureList = SortableContainer(ProcedureList);
const SortableProcedureListItem = SortableElement(ProcedureListItem);

const DragHandle = SortableHandle(() => (
  <div className="construction-tpl-man__procedure-sort-handle">
    <InlineSvg src="public/img/icon-sort.svg" />
  </div>
));
