/**
 * @file: VehicleInspectionTaskQuotationService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskQuotationListFilter, VehicleInspectionTaskQuotation, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskQuotationDetail, VehicleInspectionTaskQuotationIssue } from 'model';

export class VehicleInspectionTaskQuotationServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskQuotationListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskQuotation> : VehicleInspectionTaskQuotation[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotations', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskQuotation | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotations/:id', { id })
      .future();
  }

  async getDetailsByQuotationId(id: number, recursive = false): Promise<VehicleInspectionTaskQuotationDetail[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotations/:id/details', { id, recursive })
      .future();
  }

  async getIssuesByQuotationId(id: number, recursive = false): Promise<VehicleInspectionTaskQuotationIssue[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotations/:id/issues', { id, recursive })
      .future();
  }
}