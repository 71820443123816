import { constructionTplActions } from 'app/inspection/duck/actions';
import classNames from 'classnames';
import { ConstructionTemplateCategory } from 'model';
import { nanoid } from 'nanoid/non-secure';
import { MouseEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { getString } from 'shared/components';
import { array_move } from 'utils';
import { useConstructionTemplateConfigContext } from './Context';
import { isCategoryConfigValid } from './util';
import { WithFullAccess, useHasFullAccess } from './WithFullAccess';

export const CategoryPanel = memo(() => {
  const dispatch = useDispatch();

  const onCategorySorted = useCallback(
    (e: { oldIndex: number; newIndex: number }) => {
      const { oldIndex, newIndex } = e;
      if (oldIndex === newIndex) return;
      dispatch(
        constructionTplActions.applyChangesToTemplateConfig(draft => {
          array_move(draft.categories, oldIndex, newIndex);
        }),
      );
    },
    [dispatch],
  );

  const onAddCategory = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const name = prompt(
        getString('construction_tpl.category.add_message'),
        '',
      )?.trim();
      if (!name) return;
      dispatch(
        constructionTplActions.applyChangesToTemplateConfig((draft, state) => {
          const categoryId = nanoid(8);
          draft.categories.push({
            id: categoryId,
            name,
            groups: [],
          });
          state.selectedSidebarItem = { type: 'category', categoryId };
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className="construction-tpl-man__category-list construction-tpl-man__side-panel">
      <h2>
        <Translate id="construction_tpl.category.title" />
        <WithFullAccess>
          <a href="#" onClick={onAddCategory}>
            <i className="la la-plus" />
          </a>
        </WithFullAccess>
      </h2>
      <SortableCategoryList
        helperClass="construction-tpl-man__category--being-dragged"
        lockAxis="y"
        useWindowAsScrollContainer={true}
        distance={3}
        onSortEnd={onCategorySorted}
      />
    </div>
  );
});

const WiredCategoryList = memo(() => {
  const { templateConfig, selectedSidebarItem } =
    useConstructionTemplateConfigContext();
  const hasFullAccess = useHasFullAccess();
  const dispatch = useDispatch();

  const onCategoryClick = useCallback(
    (category: ConstructionTemplateCategory) => {
      dispatch(
        constructionTplActions.selectSidebarItem({
          type: 'category',
          categoryId: category.id,
        }),
      );
    },
    [dispatch],
  );

  const onRemoveCategory = useCallback(
    (category: ConstructionTemplateCategory) => {
      dispatch(
        constructionTplActions.applyChangesToTemplateConfig((draft, state) => {
          const index = draft.categories.findIndex(x => x.id === category.id);
          if (index < 0) return;
          draft.categories.splice(index, 1);
          if (
            state.selectedSidebarItem?.type === 'category' &&
            state.selectedSidebarItem.categoryId === category.id
          ) {
            state.selectedSidebarItem = undefined;
          }
        }),
      );
    },
    [dispatch],
  );

  return (
    <ul>
      {templateConfig.categories.map((category, index) => (
        <SortableCategoryListItem
          category={category}
          key={category.id}
          index={index}
          selected={
            selectedSidebarItem?.type === 'category' &&
            category.id === selectedSidebarItem.categoryId
          }
          onClick={onCategoryClick}
          onRemove={onRemoveCategory}
          disabled={!hasFullAccess}
        />
      ))}
    </ul>
  );
});

const CategoryListItem = memo(
  ({
    category,
    selected,
    onClick,
    onRemove,
  }: {
    category: ConstructionTemplateCategory;
    selected?: boolean;
    onClick?: (category: ConstructionTemplateCategory) => void;
    onRemove?: (category: ConstructionTemplateCategory) => void;
  }) => {
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onClick?.(category);
      },
      [onClick, category],
    );
    const handleRemove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (confirm(getString('construction_tpl.category.confirm_remove'))) {
          onRemove?.(category);
        }
      },
      [onRemove, category],
    );
    return (
      <li
        className={classNames('construction-tpl-man__category-list-item', {
          'construction-tpl-man__category-list-item--selected': selected,
        })}
      >
        <a
          href="#"
          className="construction-tpl-man__category-list-item-name"
          onClick={handleClick}
        >
          <span>
            {category.name || <Translate id="construction_tpl.no_name" />}
          </span>
          <em>{`${category.groups.flatMap(x => x.items).length}`}</em>
        </a>
        <WithFullAccess>
          <a
            href="#"
            className="construction-tpl-man__category-list-item-remove"
          >
            <i className="la la-remove" onClick={handleRemove} />
          </a>
        </WithFullAccess>
        {!isCategoryConfigValid(category) && (
          <div className="construction-tpl-man__side-invalid-indicator">
            <i className="fa fa-question" />
          </div>
        )}
      </li>
    );
  },
);

const SortableCategoryList = SortableContainer(WiredCategoryList);
const SortableCategoryListItem = SortableElement(CategoryListItem);
