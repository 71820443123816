import { Dashboard } from './dashboard';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
  StoreList,
  CustomerList,
  OrgUserList,
  OrgMemberList,
  SubscriberList,
  OrgGroupList,
  OrgTeamList,
} from './org';

import {
  InspectionSiteCategoryList,
  InspectionSitesInventory,
  InventoryManager,
  InspectionTemplateManager,
  InspectionTemplateDetailManager,
  InventorySummary,
  InspectionToolList,
  ServiceSubjectList,
  InspectionSubjectList,
} from './inspection';

import {
  WeixinOpenAuthorization,
  CustomMenu,
  TemplateSettings,
} from './integration';

import { VehicleReceptionOrderList } from './orders';
import { VehicleInspectionTaskList } from './tasks';
import { VehicleInspectionReportList } from './reports';

import { DeliveryCheckTemplateManager } from 'app/inspection/delivery-check-templates/list';
import { DeliveryCheckTemplateDetailManager } from 'app/inspection/delivery-check-templates/detail';
import { DataExportTaskList } from 'app/data-export';
import { Settings } from 'app/settings/Settings';
import { ConstructionTemplateConfigList } from 'app/inspection/construction-template-config/list';
import { ConstructionTemplateConfigManager } from 'app/inspection/construction-template-config/Manager';
import React, { useCallback } from 'react';
import { ProductAuthorizationRequestList } from 'app/products/product-authorization-requests';
import { ProductAuthorizationContractList } from 'app/products/product-authorization-contracts';
import { AuthorizedProductList } from 'app/products/authorized-products';

export const Routes = () => {
  const renderNotFound = useCallback(() => <Redirect to="/404" />, []);
  return (
    <Switch>
      <Route
        path="/product-authorization/authorization-requests"
        component={ProductAuthorizationRequestList}
      />
      <Route
        path="/product-authorization/authorization-contracts"
        component={ProductAuthorizationContractList}
      />
      <Route
        path="/product-authorization/authorized-products"
        component={AuthorizedProductList}
      />
      <Route
        path="/inspection/categories"
        component={InspectionSiteCategoryList}
      />
      <Route
        path="/inspection/inventory/manager"
        component={InventoryManager}
      />
      <Route
        path="/inspection/inventory"
        component={InspectionSitesInventory}
      />
      <Route path="/inspection/tools" component={InspectionToolList} />
      <Route path="/inspection/summary" component={InventorySummary} />
      <Route
        path="/inspection/templates/manager"
        component={InspectionTemplateManager}
      />
      <Route
        path="/inspection/templates/detail"
        component={InspectionTemplateDetailManager}
      />
      <Route path="/inspections/orders" component={VehicleReceptionOrderList} />
      <Route path="/inspections/tasks" component={VehicleInspectionTaskList} />
      <Route
        path="/inspections/reports"
        component={VehicleInspectionReportList}
      />
      <Route
        path="/inspection/service-subjects"
        component={ServiceSubjectList}
      />
      <Route
        path="/inspection/inspection-subjects"
        component={InspectionSubjectList}
      />
      <Route
        path="/inspection/delivery-check-templates/list"
        component={DeliveryCheckTemplateManager}
      />
      <Route
        path="/inspection/delivery-check-templates/detail"
        component={DeliveryCheckTemplateDetailManager}
      />
      <Route
        path="/inspection/construction-template-config/list"
        component={ConstructionTemplateConfigList}
      />
      <Route
        path="/inspection/construction-template-config/detail"
        component={ConstructionTemplateConfigManager}
      />
      <Route path="/org/stores" component={StoreList} />
      <Route path="/org/users" component={OrgUserList} />
      <Route path="/org/groups" component={OrgGroupList} />
      <Route path="/org/teams" component={OrgTeamList} />
      <Route path="/org/members" component={OrgMemberList} />
      <Route path="/org/customers" component={CustomerList} />
      <Route path="/org/subscribers" component={SubscriberList} />
      <Route
        path="/settings/weixin-open/authorization"
        component={WeixinOpenAuthorization}
      />
      <Route path="/settings/weixin-open/menu" component={CustomMenu} />
      <Route
        path="/settings/weixin-open/templates"
        component={TemplateSettings}
      />
      <Route path="/data-export" component={DataExportTaskList} />
      <Route path="/settings/general" component={Settings} />
      <Route path="/" component={Dashboard} />
      <Route path="" component={Dashboard} />
      <Route render={renderNotFound} />
    </Switch>
  );
};
