import { constructionTplActions } from 'app/inspection/duck/actions';
import classNames from 'classnames';
import { ConstructionTemplateConfig, ConstructionTemplateInfo } from 'model';
import { nanoid } from 'nanoid/non-secure';
import { MouseEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { getString } from 'shared/components';
import { usePersistFn } from 'utils/usePersistFn';
import { useConstructionTemplateConfigContext } from './Context';
import { isTemplateConfigValid } from './util';
import { WithFullAccess } from './WithFullAccess';

function getTemplateRefCount(
  templateConfig: ConstructionTemplateConfig,
  templateId: string,
) {
  let count = 0;
  for (const category of templateConfig.categories) {
    if (category.templateId === templateId) {
      count++;
    }
    for (const group of category.groups) {
      if (group.templateId === templateId) {
        count++;
      }
      for (const item of group.items) {
        if (item.templateId === templateId) {
          count++;
        }
      }
    }
  }
  return count;
}

export const TemplatePanel = memo(() => {
  const { templateConfig, selectedSidebarItem } =
    useConstructionTemplateConfigContext();

  const dispatch = useDispatch();

  const onTemplateClick = useCallback(
    (template: ConstructionTemplateInfo) => {
      dispatch(
        constructionTplActions.selectSidebarItem({
          type: 'template',
          templateId: template.id,
        }),
      );
    },
    [dispatch],
  );

  const onAddTemplate = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const name = prompt(
        getString('construction_tpl.template.add_message'),
        '',
      )?.trim();
      if (!name) return;
      dispatch(
        constructionTplActions.applyChangesToTemplateConfig((draft, state) => {
          const templateId = nanoid(8);
          draft.templates.push({
            id: templateId,
            name,
            procedures: [],
          });
          state.selectedSidebarItem = { type: 'template', templateId };
        }),
      );
    },
    [dispatch],
  );

  const onRemoveTemplate = usePersistFn(
    (template: ConstructionTemplateInfo) => {
      const refCount = getTemplateRefCount(templateConfig, template.id);
      if (refCount > 0) return;
      dispatch(
        constructionTplActions.applyChangesToTemplateConfig((draft, state) => {
          const templateId = template.id;
          const index = draft.templates.findIndex(x => x.id === templateId);
          if (index >= 0) {
            draft.templates.splice(index, 1);
            if (
              state.selectedSidebarItem?.type === 'template' &&
              state.selectedSidebarItem.templateId === templateId
            ) {
              state.selectedSidebarItem = undefined;
            }
          }
        }),
      );
    },
  );

  return (
    <div className="construction-tpl-man__template-list construction-tpl-man__side-panel">
      <h2>
        <Translate id="construction_tpl.template.title" />
        <WithFullAccess>
          <a href="#" onClick={onAddTemplate}>
            <i className="la la-plus" />
          </a>
        </WithFullAccess>
      </h2>
      <ul>
        {templateConfig.templates.map(template => (
          <TemplateListItem
            template={template}
            key={template.id}
            refCount={getTemplateRefCount(templateConfig, template.id)}
            selected={
              selectedSidebarItem?.type === 'template' &&
              template.id === selectedSidebarItem.templateId
            }
            onClick={onTemplateClick}
            onRemove={onRemoveTemplate}
          />
        ))}
      </ul>
    </div>
  );
});

const TemplateListItem = memo(
  ({
    template,
    selected,
    refCount,
    onClick,
    onRemove,
  }: {
    template: ConstructionTemplateInfo;
    refCount: number;
    selected?: boolean;
    onClick?: (template: ConstructionTemplateInfo) => void;
    onRemove?: (template: ConstructionTemplateInfo) => void;
  }) => {
    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onClick?.(template);
      },
      [onClick, template],
    );
    const handleRemove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (confirm(getString('construction_tpl.template.confirm_remove'))) {
          onRemove?.(template);
        }
      },
      [onRemove, template],
    );
    return (
      <li
        className={classNames('construction-tpl-man__template-list-item', {
          'construction-tpl-man__template-list-item--selected': selected,
        })}
      >
        <a
          href="#"
          onClick={handleClick}
          className="construction-tpl-man__template-list-item-name"
        >
          <span>
            {template.name || <Translate id="construction_tpl.no_name" />}
          </span>
          <em>
            <Translate
              id="construction_tpl.template.ref_count"
              data={{ refCount }}
            />
          </em>
        </a>
        {refCount === 0 && (
          <WithFullAccess>
            <a
              href="#"
              className="construction-tpl-man__template-list-item-remove"
            >
              <i className="la la-remove" onClick={handleRemove} />
            </a>
          </WithFullAccess>
        )}
        {!isTemplateConfigValid(template) && (
          <div className="construction-tpl-man__side-invalid-indicator">
            <i className="fa fa-question" />
          </div>
        )}
      </li>
    );
  },
);
