/**
 * @file: config.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Menu } from 'lib/metronic/components';

export const MenuConfig: Menu = {
  sections: [
    {
      key: 'dashboard',
      title: 'menu.system_overview',
      items: [
        {
          id: 'dashboard',
          text: 'menu.dashboard',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/design/layers.svg')
            .default,
          url: '/',
        },
        {
          id: 'data-export',
          text: 'menu.data_export',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/export.svg')
            .default,
          url: '/data-export',
        },
      ],
    },
    {
      key: 'products_authorization',
      title: 'menu.products_authorization.menu_text',
      items: [
        {
          id: 'product_authorization.authorization_requests',
          text: 'menu.products_authorization.authorization_requests',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/general/notification2.svg')
            .default,
          url: '/product-authorization/authorization-requests',
        },
        {
          id: 'product_authorization.authorization_contracts',
          text: 'menu.products_authorization.authorization_contracts',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/home/book-open.svg')
            .default,
          url: '/product-authorization/authorization-contracts',
        },
        {
          id: 'product_authorization.authorized_products',
          text: 'menu.products_authorization.authorized_products',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/shopping/wallet.svg')
            .default,
          url: '/product-authorization/authorized-products',
        },
      ],
    },
    {
      key: 'inspections',
      title: 'menu.inspections.menu_text',
      items: [
        {
          id: 'inspections.orders',
          text: 'menu.inspections.orders',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/code/compiling.svg')
            .default,
          url: '/inspections/orders',
        },
        {
          id: 'inspections.tasks',
          text: 'menu.inspections.tasks',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/design/pen-and-ruller.svg')
            .default,
          url: '/inspections/tasks',
        },
        {
          id: 'inspections.reports',
          text: 'menu.inspections.reports',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/design/color-profile.svg')
            .default,
          url: '/inspections/reports',
        },
      ],
    },
    {
      key: 'org',
      title: 'menu.org.menu_text',
      items: [
        {
          id: 'org.stores',
          text: 'menu.org.stores',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/map/marker1.svg')
            .default,
          url: '/org/stores',
        },
        {
          id: 'org.users',
          text: 'menu.org.users',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/group.svg')
            .default,
          url: '/org/users',
        },
        {
          id: 'org.groups',
          text: 'menu.org.groups',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/code/git4.svg')
            .default,
          url: '/org/groups',
        },
        {
          id: 'org.teams',
          text: 'menu.org.teams',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/user-folder.svg')
            .default,
          url: '/org/teams',
        },
        {
          id: 'org.members',
          text: 'menu.org.members',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/contact1.svg')
            .default,
          url: '/org/members',
        },
        {
          id: 'org.customers',
          text: 'menu.org.customers',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/adress-book2.svg')
            .default,
          url: '/org/customers',
        },
        {
          id: 'org.subscribers',
          text: 'menu.org.subscribers',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/user-folder.svg')
            .default,
          url: '/org/subscribers',
        },
      ],
    },
    {
      key: 'inspection',
      title: 'menu.inspection.menu_text',
      items: [
        {
          id: 'inspection.categories',
          text: 'menu.inspection.categories',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/folder.svg')
            .default,
          url: '/inspection/categories',
        },
        {
          id: 'inspection.inventory',
          text: 'menu.inspection.inventory',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/home/library.svg')
            .default,
          url: '/inspection/inventory',
        },
        {
          id: 'inspection.inventory_manager',
          text: 'menu.inspection.inventory_manager',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/layout/layout-4-blocks.svg')
            .default,
          url: '/inspection/inventory/manager',
        },
        {
          id: 'inspection.templates_manager',
          text: 'menu.inspection.templates_manager',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg')
            .default,
          url: '/inspection/templates/manager',
        },
        {
          id: 'inspection.summary',
          text: 'menu.inspection.summary',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/home/book-open.svg')
            .default,
          url: '/inspection/summary',
        },
        {
          id: 'inspection.tools',
          text: 'menu.inspection.tools',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/shopping/box1.svg')
            .default,
          url: '/inspection/tools',
        },
        {
          id: 'inspection.service_subjects',
          text: 'menu.inspection.service_subjects',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/layout/layout-left-panel-2.svg')
            .default,
          url: '/inspection/service-subjects',
        },
        {
          id: 'inspection.inspection_subjects',
          text: 'menu.inspection.inspection_subjects',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/layout/layout-4-blocks.svg')
            .default,
          url: '/inspection/inspection-subjects',
        },
        {
          id: 'inspection.construction_template_config',
          text: 'menu.inspection.construction_template_config',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg')
            .default,
          url: '/inspection/construction-template-config/list',
        },
        {
          id: 'inspection.delivery_check_templates',
          text: 'menu.inspection.delivery_check_templates',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/files/selected-file.svg')
            .default,
          url: '/inspection/delivery-check-templates/list',
        },
      ],
    },
    {
      key: 'settings',
      title: 'menu.settings.menu_text',
      items: [
        {
          id: 'settings.config',
          text: 'menu.settings.config',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/code/settings4.svg')
            .default,
          url: '/settings/general',
        },
        {
          id: 'settings.weixin_open.authorization',
          text: 'menu.settings.weixin_open.authorization',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/general/attachment1.svg')
            .default,
          url: '/settings/weixin-open/authorization',
        },
        {
          id: 'settings.weixin_open.menu',
          text: 'menu.settings.weixin_open.menu',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/text/menu.svg')
            .default,
          url: '/settings/weixin-open/menu',
        },
        {
          id: 'settings.weixin_open.templates',
          text: 'menu.settings.weixin_open.templates',
          icon: require('!@svgr/webpack!lib/metronic/assets/icons/svg/communication/chat1.svg')
            .default,
          url: '/settings/weixin-open/templates',
        },
      ],
    },
  ],
};
