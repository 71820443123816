import classNames from 'classnames';
import { InlineSvg } from 'lib/components';
import { ConstructionTemplateGroup, ConstructionTemplateWorkItem } from 'model';
import { MouseEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { getString } from 'shared/components';
import { useConstructionTemplateConfigContext } from './Context';
import { WithFullAccess, useHasFullAccess } from './WithFullAccess';

export const Group = memo(
  ({
    group,
    onEdit,
    onRemove,
    onMoveUp,
    onMoveDown,
    onAddWorkItem,
    onItemRemove,
    onItemClick,
    onItemMove,
  }: {
    group: ConstructionTemplateGroup;
    onEdit?: (group: ConstructionTemplateGroup) => void;
    onRemove?: (group: ConstructionTemplateGroup) => void;
    onMoveUp?: (group: ConstructionTemplateGroup) => void;
    onMoveDown?: (group: ConstructionTemplateGroup) => void;
    onAddWorkItem?: (group: ConstructionTemplateGroup, name: string) => void;
    onItemClick?: (item: ConstructionTemplateWorkItem, groupId: string) => void;
    onItemRemove?: (
      item: ConstructionTemplateWorkItem,
      groupId: string,
    ) => void;
    onItemMove?: (
      group: ConstructionTemplateGroup,
      from: number,
      to: number,
    ) => void;
  }) => {
    const {
      templateConfig: { templates },
    } = useConstructionTemplateConfigContext();
    const template = templates.find(x => x.id === group.templateId);

    const handleMoveUp = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onMoveUp?.(group);
      },
      [onMoveUp, group],
    );

    const handleMoveDown = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onMoveDown?.(group);
      },
      [onMoveDown, group],
    );

    const handleEdit = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onEdit?.(group);
      },
      [onEdit, group],
    );

    const handleRemove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (confirm(getString('construction_tpl.group.confirm_remove'))) {
          onRemove?.(group);
        }
      },
      [onRemove, group],
    );

    const handleItemMoved = useCallback(
      (e: { oldIndex: number; newIndex: number }) => {
        const { oldIndex, newIndex } = e;
        if (oldIndex === newIndex) return;
        onItemMove?.(group, oldIndex, newIndex);
      },
      [onItemMove, group],
    );

    return (
      <div className="construction-tpl-man__group">
        <h3>
          <WithFullAccess>
            <DragHandle />
          </WithFullAccess>

          <span className="construction-tpl-man__group-title">
            {group.name}&nbsp;{`(${group.items.length})`}
          </span>
          <dl className="construction-tpl-man__group-template-info">
            <dt>
              <Translate id="construction_tpl.group.template_label" />
            </dt>
            <dd
              className={classNames({
                'construction-tpl-man__group-template-info-override':
                  template != null,
              })}
            >
              {template ? (
                template.procedures.map(x => x.name).join('/')
              ) : (
                <Translate id="construction_tpl.group.inherited_from_category" />
              )}
            </dd>
          </dl>
          <WithFullAccess>
            <div className="construction-tpl-man__group-actions">
              {onMoveUp && (
                <a
                  href="#"
                  onClick={handleMoveUp}
                  style={{
                    marginLeft: '0.15rem',
                    fontSize: '1.4rem',
                    transform: 'scale(1)',
                  }}
                >
                  <i className="la la-caret-up" />
                </a>
              )}
              {onMoveDown && (
                <a
                  href="#"
                  onClick={handleMoveDown}
                  style={{
                    marginLeft: '0.15rem',
                    fontSize: '1.4rem',
                    transform: 'rotate(-180deg) scale(1)',
                  }}
                >
                  <i className="la la-caret-up" />
                </a>
              )}
              <a
                href="#"
                onClick={handleEdit}
                style={{
                  marginLeft: '0.35rem',
                  fontSize: '1.2rem',
                }}
              >
                <i className="la la-edit" />
              </a>
              <a
                href="#"
                onClick={handleRemove}
                style={{
                  marginLeft: '0.35rem',
                  fontSize: '1.2rem',
                }}
              >
                <i className="la la-trash" />
              </a>
            </div>
          </WithFullAccess>
        </h3>
        <SortableItemList
          helperClass="construction-tpl-man__group-item--being-dragged"
          useWindowAsScrollContainer={false}
          axis="xy"
          //lockAxis="xy"
          distance={3}
          group={group}
          onSortEnd={handleItemMoved}
          onAddWorkItem={onAddWorkItem}
          onItemClick={onItemClick}
          onItemRemove={onItemRemove}
        />
      </div>
    );
  },
);

const ItemList = memo(
  ({
    group,
    onAddWorkItem,
    onItemClick,
    onItemRemove,
  }: {
    group: ConstructionTemplateGroup;
    onAddWorkItem?: (group: ConstructionTemplateGroup, name: string) => void;
    onItemClick?: (item: ConstructionTemplateWorkItem, groupId: string) => void;
    onItemRemove?: (
      item: ConstructionTemplateWorkItem,
      groupId: string,
    ) => void;
  }) => {
    const hasFullAccess = useHasFullAccess();
    const handleAddWorkItem = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        const name = prompt(
          getString('construction_tpl.group.new_item_name_msg'),
          '',
        )?.trim();
        if (!name) return;
        if (group.items.some(x => x.name === name)) {
          alert(
            getString('construction_tpl.group.item_exists_error_msg', { name }),
          );
          return;
        }
        onAddWorkItem?.(group, name);
      },
      [group, onAddWorkItem],
    );

    return (
      <div className="construction-tpl-man__group-item-list">
        {group.items.map((item, index) => (
          <SortableItem
            key={item.id}
            index={index}
            item={item}
            groupId={group.id}
            onClick={onItemClick}
            onRemove={onItemRemove}
            disabled={!hasFullAccess}
          />
        ))}
        <WithFullAccess>
          <div
            className="construction-tpl-man__group-item"
            style={{
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <a
              href="#"
              onClick={handleAddWorkItem}
              style={{
                color: '#716aca',
                fontSize: '1.2rem',
                display: 'block',
                padding: '0 1rem',
              }}
            >
              <i className="la la-plus" />
            </a>
          </div>
        </WithFullAccess>
      </div>
    );
  },
);

const Item = memo(
  ({
    groupId,
    item,
    onClick,
    onRemove,
  }: {
    groupId: string;
    item: ConstructionTemplateWorkItem;
    onClick?: (item: ConstructionTemplateWorkItem, groupId: string) => void;
    onRemove?: (item: ConstructionTemplateWorkItem, groupId: string) => void;
  }) => {
    const handleRemove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onRemove?.(item, groupId);
      },
      [onRemove, item, groupId],
    );

    const handleEdit = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onClick?.(item, groupId);
      },
      [onClick, item, groupId],
    );

    return (
      <div
        className={classNames('construction-tpl-man__group-item', {
          'construction-tpl-man__group-item--with-template': item.templateId,
        })}
      >
        <span>{item.name}</span>
        <a
          href="#"
          className="construction-tpl-man__group-item-remove"
          onClick={handleRemove}
        >
          <i className="la la-remove" />
        </a>
        <a
          href="#"
          className="construction-tpl-man__group-item-edit"
          onClick={handleEdit}
        >
          <i className="la la-pencil" />
        </a>
      </div>
    );
  },
);

const SortableItemList = SortableContainer(ItemList);
const SortableItem = SortableElement(Item);

const DragHandle = SortableHandle(() => (
  <div className="construction-tpl-man__group-sort-handle">
    <InlineSvg src="public/img/icon-sort.svg" />
  </div>
));
