/**
 * @file: VehicleInspectionTaskQuotationIssueService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { VehicleInspectionTaskQuotationIssueServiceBase } from './base/VehicleInspectionTaskQuotationIssueService';

export class VehicleInspectionTaskQuotationIssueService extends VehicleInspectionTaskQuotationIssueServiceBase {
  // add custom service implementation here
}
