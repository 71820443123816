import { useAppContext } from 'app/AppContext';
import { useConstructionTemplateConfigContext } from 'app/inspection/construction-template-config/Context';
import { AclObjectList, Identity } from 'model';
import React, { memo } from 'react';
import { Translate } from 'react-localize-redux';

const rights = [AclObjectList.VehicleConstructionTemplateFullAccess];

export const useHasFullAccess = () => {
  const { user, identity } = useAppContext();
  const { target } = useConstructionTemplateConfigContext();

  const hasRights =
    target.type !== 'system' &&
    ((target.type === 'org' && user.isOrgRootUser) ||
      (target.type === 'store' &&
        identity.visibleStoreSet.has(target.storeId))) &&
    (Array.isArray(rights)
      ? identity.hasAccessRights(...rights)
      : identity.hasAccessRights(rights));

  return hasRights;
};

export const WithFullAccess = memo(
  ({
    accessDeniedView,
    silent = true,
    children,
  }: {
    accessDeniedView?: any;
    silent?: boolean;
    children?: ((identity: Identity, hasRights: boolean) => any) | any;
  }) => {
    const { identity } = useAppContext();
    const hasRights = useHasFullAccess();

    if (typeof children === 'function') {
      return (children as any)(identity, hasRights);
    }

    if (!hasRights) {
      if (silent) return null;

      return (
        <div className="restricted-no-rights kt-grid__item kt-grid__item--fluid">
          {accessDeniedView || (
            <div className="restricted-no-rights--default-content">
              <i className="la la-lightbulb-o icon" />
              <Translate id="access_denied_placeholder" />
            </div>
          )}
        </div>
      );
    }

    const count = React.Children.count(children);

    if (count === 1) return children;

    return <>{children}</>;
  },
);
