import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/inspection', {
  NodeSelected: '',
  NodeExpanded: '',
  NodeCollapsed: '',
  NodeMouseEnter: '',
  NodeMouseLeave: '',

  FilterChanged: '',
  SiteKeywordFilterChanged: '',
  SiteSourceChanged: '',
  InventoryManagerSetTargetCategoryId: '',
  SetExpandedCollapsedNodes: '',

  SystemNodeSelected: '',
  SystemNodeExpanded: '',
  SystemNodeCollapsed: '',
  SystemNodeMouseEnter: '',
  SystemNodeMouseLeave: '',
  SystemFilterChanged: '',
  SetSystemExpandedCollapsedNodes: '',

  CustomNodeSelected: '',
  CustomNodeExpanded: '',
  CustomNodeCollapsed: '',
  CustomNodeMouseEnter: '',
  CustomNodeMouseLeave: '',
  CustomFilterChanged: '',
  SetCustomExpandedCollapsedNodes: '',

  TemplateSourceChanged: '',

  TemplateDetailReady: '',
  TemplateDetailUpdated: '',
  TemplateDetailAddCategory: '',
  TemplateDetailEditCategory: '',
  TemplateDetailEditCategoryChanged: '',
  TemplateDetailEditCategoryCommitted: '',
  TemplateDetailEditCategoryCancelled: '',
  TemplateDetailRemoveCategory: '',
  TemplateDetailCommitRemoveCategory: '',
  TemplateDetailCancelRemoveCategory: '',
  TemplateDetailExpandCategory: '',
  TemplateDetailCollapseCategory: '',
  TemplateDetailAddGroup: '',
  TemplateDetailEditGroup: '',
  TemplateDetailEditGroupChanged: '',
  TemplateDetailEditGroupCommitted: '',
  TemplateDetailEditGroupCancelled: '',
  TemplateDetailRemoveGroup: '',
  TemplateDetailCommitRemoveGroup: '',
  TemplateDetailCancelRemoveGroup: '',
  TemplateDetailCategoryMoved: '',
  TemplateDetailGroupSelected: '',
  TemplateDetailAllGroupsSelected: '',
  TemplateDetailGroupMoved: '',
  TemplateDetailAddSite: '',
  TemplateDetailRemoveSite: '',
  TemplateDetailAddSites: '',
  TemplateDetailRemoveSites: '',
  TemplateDetailRemoveSitesCommitted: '',
  TemplateDetailRemoveSitesCancelled: '',
  TemplateDetailShowSiteList: '',
  TemplateDetailHideSiteList: '',
  TemplateDetailShowPreview: '',
  TemplateDetailHidePreview: '',
  TemplateDetailSiteMoved: '',
  TemplateDetailSiteEnabled: '',
  TemplateDetailSiteDisabled: '',
  TemplateDetailSiteSelected: '',
  TemplateDetailSiteDeselected: '',
  TemplateDetailToggleSelectAll: '',
  TemplateDetailSiteListKeywordChange: '',
  TemplateDetailEditSiteRels: '',
  TemplateDetailEndEditSiteRels: '',
  TemplateDetailSiteRelsChanged: '',
  TemplateDetailRemoveSiteRels: '',
  TemplateDetailSiteRelMoved: '',
  TemplateDetailSave: '',
  TemplateDetailSaveSuccess: '',
  TemplateDetailSaveFailed: '',
  TemplateDetailSiteListSortTypeChanged: '',
  TemplateDetailSiteDefaultHiddenSet: '',
  TemplateDetailSiteRequiredSet: '',

  DeliveryCheckTemplateDetailReady: '',
  ApplyDefaultDeliveryCheckTemplateConf: '',
  AddDeliveryCheckTemplateItem: '',
  DeliveryCheckTemplateItemChanged: '',
  RemoveDeliveryCheckTemplateItem: '',
  RemoveDeliveryCheckTemplateItemConfirmed: '',
  RemoveDeliveryCheckTemplateItemCancelled: '',
  DeliveryCheckTemplateItemMoved: '',
  AddDeliveryCheckTemplateItemOption: '',
  DeliveryCheckTemplateItemOptionChanged: '',
  DeliveryCheckTemplateItemOptionDefaultCheckChanged: '',
  DeliveryCheckTemplateItemOptionIsExpectedChanged: '',
  RemoveDeliveryCheckTemplateItemOption: '',
  RemoveDeliveryCheckTemplateItemOptionConfirmed: '',
  RemoveDeliveryCheckTemplateItemOptionCancelled: '',
  DeliveryCheckTemplateDetailSave: '',
  DeliveryCheckTemplateDetailSaveSuccess: '',
  DeliveryCheckTemplateDetailSaveFailed: '',
  ValidateDeliveryCheckTemplateDetail: '',

  BeginConfigureConstructionTemplateConfig: '',
  EndConfigureConstructionTemplateConfig: '',
  ConstructionTemplateConfigSelectSidebarItem: '',
  ConstructionTemplateConfigChanged: '',
  ResetConstructionTemplateConfigChanges: '',
  SaveConstructionTemplateConfigSuccess: '',
  ToggleConstructionTemplateConfigEnabled: '',
});
