/**
 * @file: VehicleInspectionTaskQuotationFormService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

/* tslint:disable:max-line-length */

import { APIService } from 'lib/restful-client/api.service';
import { VehicleInspectionTaskQuotationFormListFilter, VehicleInspectionTaskQuotationForm, ListResult, SortInfo, ListQueryOptions, PagedListQueryOptions, VehicleInspectionTaskQuotation } from 'model';

export class VehicleInspectionTaskQuotationFormServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskQuotationFormListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions
  ): Promise<TOptions extends PagedListQueryOptions ? ListResult<VehicleInspectionTaskQuotationForm> : VehicleInspectionTaskQuotationForm[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-forms', Object.assign({
        filter: filter && JSON.stringify(filter) || undefined,
        sorter: sorter && JSON.stringify(sorter) || undefined,
        offset,
        limit
      }, options)).future();
  }

  async get(id: number): Promise<VehicleInspectionTaskQuotationForm | null> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-forms/:id', { id })
      .future();
  }

  async getFormByFormNo(formNo: string): Promise<VehicleInspectionTaskQuotationForm | null> {
    if (!formNo) {
      throw new Error('missing formNo parameter');
    }
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-forms/query/formno', { formNo })
      .future();
  }

  async getFormByTaskId(taskId: number): Promise<VehicleInspectionTaskQuotationForm | null> {
    if (!taskId) {
      throw new Error('missing taskId parameter');
    }
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-forms/query/taskid', { taskId })
      .future();
  }

  async create(vehicleInspectionTaskQuotationForm: Partial<VehicleInspectionTaskQuotationForm>): Promise<VehicleInspectionTaskQuotationForm> {
    return await this.api.post()
      .url('/vehicle-inspection-task-quotation-forms')
      .data(vehicleInspectionTaskQuotationForm)
      .future();
  }

  async update(vehicleInspectionTaskQuotationForm: Partial<VehicleInspectionTaskQuotationForm>): Promise<VehicleInspectionTaskQuotationForm> {
    return await this.api.put()
      .url('/vehicle-inspection-task-quotation-forms/:id', { id: vehicleInspectionTaskQuotationForm.id })
      .data(vehicleInspectionTaskQuotationForm)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete()
      .url('/vehicle-inspection-task-quotation-forms/:id', { id })
      .future();
  }

  async getDetailsByFormId(id: number, recursive = false): Promise<VehicleInspectionTaskQuotation[]> {
    return await this.api.get()
      .url('/vehicle-inspection-task-quotation-forms/:id/details', { id, recursive })
      .future();
  }

  async createVehicleInspectionTaskQuotation(quotation: Partial<VehicleInspectionTaskQuotation>): Promise<VehicleInspectionTaskQuotation> {
    const id = quotation.formId;
    if (!id) {
      throw new Error('quotation.formId is required. ');
    }
    return await this.api.post()
      .url('/vehicle-inspection-task-quotation-forms/:id/details')
      .data(quotation)
      .future();
  }

  async updateVehicleInspectionTaskQuotation(quotation: VehicleInspectionTaskQuotation): Promise<VehicleInspectionTaskQuotation> {
    const id = quotation.formId;
    if (!id) {
      throw new Error('quotation.formId is required. ');
    }
    return await this.api.put()
      .url('/vehicle-inspection-task-quotation-forms/:id/details')
      .data(quotation)
      .future();
  }

  async deleteVehicleInspectionTaskQuotation(
    formId: number | VehicleInspectionTaskQuotation, quotationId?: number
  ): Promise<void> {
    if (formId && typeof formId === 'object') {
      quotationId = formId.id;
      if (!formId.formId) {
        throw new Error('formId cannot be null or undefined');
      }
      formId = formId.formId;
    }
    if (!formId || !quotationId) {
      throw new Error('formId and quotationId are both required');
    }
    return void await this.api.delete()
      .url('/vehicle-inspection-task-quotation-forms/:formId/details/:quotationId', { formId, quotationId })
      .future();
  }
}